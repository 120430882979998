.welcome_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  padding: 50px 10px 50px 10px;
  background-color: rgba(210, 190, 173, 1);
  background-image: linear-gradient(
    -90deg,
    rgba(210, 190, 173, 1) 50%,
    rgba(255, 255, 255, 1) 41%
  );
  position: relative;
}
.welcome_header__container {
  margin-top: 30px;
  font-size: 1.8rem;
  color: var(--black);
}
.welcome_text__top {
  font-size: 1.3rem;
  text-align: center;
  font-style: italic;
  opacity: 0.8;
  font-family: var(--font);
}
.welcome_text__bottom {
  padding-top: 40px;
  display: block;
  word-break: break-word;
  padding: 5px;
  color: var(--black);
  /* width: 700px; */
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  line-height: 2.2rem;
  text-transform: uppercase;
}
.welcome_header {
  text-transform: uppercase;
  /* margin-top: 50px; */
  text-align: center;
  line-height: 5.2rem;
  word-break: break-word;
  font-weight: 500;
  font-family: 'Old Standard TT', serif;
}
.header_italic {
  font-style: italic;
  text-transform: none;
  font-weight: 300;
  opacity: 0.8;
}
.welcome_image__container {
  width: 350px;
  height: 350px;
  position: relative;
  overflow: hidden;
}
.slider_wrapper {
  position: absolute;
  opacity: 0;
  transform: translateX(0%);
  width: 100%;
  height: 100%;
  animation: fade 20s infinite;
}
.slider_wrapper:nth-child(1) {
  animation-delay: 0s;
}
.slider_wrapper:nth-child(2) {
  animation-delay: 4s;
}
.slider_wrapper:nth-child(3) {
  animation-delay: 7s;
}
.slider_wrapper:nth-child(4) {
  animation-delay: 10s;
}
.slider_wrapper:nth-child(5) {
  animation-delay: 13s;
}
.slider_wrapper:nth-child(6) {
  animation-delay: 16s;
}
.welcome_image__container:hover .slider_wrapper {
  animation-play-state: paused;
}
.welcome_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.welcome_text__bottom {
  width: 100%;
}

.link_arrow {
  color: var(--black);
  transform: translateY(0);
  animation: jumping 2s infinite ease both;
}

@media (min-width: 900px) {
  .welcome_text__top {
    margin-top: 20px;
    font-size: inherit;
    text-align: center;
    font-style: italic;
    opacity: 0.8;
  }
  .welcome_text__bottom {
    width: 100%;
    display: block;
    text-align: center;
  }
  .welcome_header {
    margin-top: 60px;
    margin-right: 80px;
    padding: 2%;
    width: 700px;
    font-size: 5rem;
    line-height: 6.2rem;
  }
  .welcome_image__container {
    width: 500px;
  }
}
@keyframes jumping {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
