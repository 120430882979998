.portfolio_section {
  background-color: inherit;
}
.portfolio_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.intro_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--black);
  align-items: center;
  padding: 20px;
  margin-top: 30px;
  background-color: var(--white);
}
.intro_heading {
  width: 100%;
  border-left: 5px solid var(--black);
  padding: 10px 20px 10px 20px;
  text-transform: uppercase;
  font-size: 2.8rem;
  font-weight: 500;
  font-family: var(--font);
}
.intro_description {
  background-color: hsl(27, 23%, 92%);
  padding: 20px;
  font-size: 1rem;
  margin-top: 70px;
  font-family: var(--secondfont);
  word-break: break-word;
  line-height: 20px;
}
.intro_text {
  margin: 15px 0 15px 0;
}
.intro_span, .intro_link {
color: inherit;
text-transform: uppercase;
font-weight: 600;
font-family: var(--font);
}
.intro_link:hover {
  color: var(--beige);
}
.portfolio_lists__container {
  padding: 0px;
  width: 0 auto;
}
.portfolio_lists {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  background-color: var(--white);
  list-style: none;
  /* -webkit-box-shadow: 0px 0px 30px -16px rgba(68, 60, 60, 1);
  -moz-box-shadow: 0px 0px 30px -16px rgba(68, 60, 60, 1);
  box-shadow: 0px 0px 30px -16px rgba(68, 60, 60, 1); */
}
.portfolio_item {
  width: 280px;
  height: 330px;
  margin: 30px 15px;
  padding: 20px;
  /* border-radius: 3px; */
  background-color: var(--beige);
  -webkit-box-shadow: 0px 39px 61px -18px rgba(68, 60, 60, 1);
  -moz-box-shadow: 0px 39px 61px -18px rgba(68, 60, 60, 1);
  box-shadow: 0px 39px 61px -18px rgba(68, 60, 60, 1);
}
.portfolio_description {
  padding-top: 5px;
  transform: scale(1);
  transition: transform 0.3s linear;
  text-align: center;
}
.portfolio_description:hover {
  transform: scale(1.05);
}
.portfolio_links {
  text-decoration: none;
  color: var(--black);
}
.portfolio_image {
  object-fit: cover;
  width: 100%;
  height: 185px;
  padding: 5px 15px 10px 15px;
  background: var(--white);
  transition: filter 0.3s linear;
}
.portfolio_title,
.portfolio_text {
  background-color: var(--white);
  padding: 10px 5px 10px 5px;
}
.portfolio_title {
  padding-bottom: 5px;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.portfolio_text {
  color: hsl(0, 2%, 35%);
  line-height: 1.5rem;
  text-decoration-line: underline;
  text-underline-offset: 0.4em;
}
.portfolio_text:hover {
  color: var(--beige);
}

@media (min-width: 900px) {
  .portfolio_lists__container {
    width: 80vw;
  }
  .portfolio_item {
    margin: 60px;
  }
  .intro_description {
    max-width: 1440px;
    font-size: 1.2rem;
    line-height: 25px;
  }
  .intro_heading {
    font-size: 5rem;
    margin-left: 50px;
    
  }
}
