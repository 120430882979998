.wrapper {
  margin-top: 100px;
  margin-bottom: 80px;
  color: var(--black);
  padding: 10px;
}
.boxes_header {
  word-break: break-word;
  font-size: 2.8rem;
  text-align: center;
  /* font-style: italic; */
  font-weight: 300;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-family: var(--font);
}
.boxes_header__span {
  opacity: 0.8;
}
.header_underline {
  display: block;
  margin: 0 auto;
  width: 50%;
  height: 1px;
  background-color: var(--black);
}
.boxes_container {
  margin-top: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  /* background-color: rgb(219, 203, 195); */
}
.box_one,
.box_two,
.box_three {
  position: relative;
  background-color: var(--white);
  width: 350px;
  height: 300px;
  display: flex;
  font-family: var(-secondfont);
  justify-content: center;
  align-items: center;
  margin: 15px;
  transform: scale(1);
  overflow: hidden;
}
.box_one:hover .box_image,
.box_two:hover .box_image,
.box_three:hover .box_image {
  opacity: 1;
  transform: scale(1.1);
}
.woman_image {
  width: 40%;
  top: 20%;
  position: absolute;
}
.box_image {
  position: absolute;
  width: 100%;
  top: 0;
  object-fit: contain;
  opacity: 0.7;
  z-index: -1;
  transition: 0.5s all linear;
}

.box_border {
  position: absolute;
  border: 2px solid var(--white);
  width: 300px;
  align-self: center;
  height: 250px;
}
.box_one__link,
.box_two__link,
.box_three__link {
  font-size: 1.2rem;
  text-transform: uppercase;
  /* padding: 100px;
  border: 1px solid white; */
  text-decoration: none;
  color: var(--black);
  font-weight: 600;
  isolation: isolate;
  text-align: center;
  padding: 10px 25px;
  min-width: 166px;
  background-color: var(--white);
  transition: 0.3s all linear;
}
.box_one__link:hover,
.box_two__link:hover,
.box_three__link:hover {
  color: var(--white);
  background-color: var(--black);
}
.text_section {
  position: relative;
  font-family: var(--secondfont);
  word-break: break-word;
  margin: 0 auto;
  max-width: 500px;
  padding: 15px;
  line-height: 20px;
}

.text_about {
  margin: 15px 0 15px 0;
}
.text_span {
  text-decoration: none;
  color: inherit;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--font);
}
.text_section__bg {
  display: none;
}
@media (min-width: 900px) {
  .text_section {
    margin-top: 30px;
    line-height: 25px;
    font-size: 1.3rem;
    max-width: 800px;
    text-align: center;
  }
  .text_about {
    margin: 20px;
  }
  .text_span {
    font-size: 1.4rem;
  }
  .text_section__bg {
    font-family: var(--font);
    display: block;
    position: absolute;
    text-align: center;
    width: auto;
    top: 40px;
    left: 0;
    text-transform: uppercase;
    font-size: 13rem;
    color: var(--black);
    text-shadow: -1px -1px 0 var(--black), 1px -1px 0 var(--black),
      -1px 1px 0 var(--black), 1px 1px 0 var(--black);
    letter-spacing: 60px;
    line-height: 150px;
    opacity: 0.1;
    z-index: -10;
  }
}
@media (max-width: 500px) {
  .box_one,
  .box_two,
  .box_three {
    width: 300px;
    height: 230px;
  }
  .box_one__link,
  .box_two__link,
  .box_three__link {
    font-size: 1rem;
    padding: 5px 30px;
  }
  .box_border {
    width: 270px;
    height: 200px;
  }
}
