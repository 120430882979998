.cookies_container {
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    border: 3px solid var(--white);
    width: 100%;
    margin: 0 auto;
    background-color: var(--white);
}
.cookies_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    background-color: var(--beige);
    gap: 20px;
    margin: 0 auto;
    color: var(--black);
}
.active {
    bottom: 0%;
}
.cookies_heading {
    margin-top: 10px;
    font-size: 1rem;
    width: 100%;
}
.cookies_text {
    margin-top: 15px;
    font-size: 0.7rem;
}
.cookies_link {
    color: inherit;
    transition: .2s color ease-in;
}
.cookies_link:hover {
    color: var(--white);
}
.button-container {
    display: flex;
    gap: 20px;
}
.cookies_button {
    padding: 10px 20px 10px 20px;
    max-width: 350px;
    font-size: 1rem;
    color: var(--black);
    border: 1px solid var(--black);
    cursor: pointer;
    background: var(--white);
    /* text-transform: uppercase; */
    transition: .3s all linear;
}
.cookies_button:hover {
    color: var(--white);
    background-color: var(--black);
}

@media (min-width: 900px) {
    .cookies_wrapper {
        flex-direction: row;
        padding: 20px;
    }
    .cookies_container {
        width: 80%;
    }
    .cookies_heading {
        margin-top: 0;
    }
    .cookies_button {
        padding: 10px 30px 10px 30px;
        font-size: 1.1rem;
    }
}