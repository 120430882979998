html {
  font-size: 13px;
  scroll-behavior: smooth;
  font-family: var(--secondfont);
  min-height: 100%;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  outline: none;
}
:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 6%, 25%);
  --bg-rose: hsl(274, 47%, 97%);
  --beige: hsl(28, 29%, 75%);
  --font: 'Old Standard TT', serif;
  --secondfont: 'Montserrat', sans-serif;
  --insta-basic: #f09433;
  --insta-colors: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  /* // font-family: 'Dancing Script', cursive;
  // font-family: 'Elsie', cursive;
  font-family: 'Old Standard TT', serif;
  // font-family: 'Montserrat', sans-serif;
font-family: 'Cormorant Garamond', serif;
  /* font-family: 'Chamberi Display'; */
  /* font-family: 'Cormorant', serif; */
}
