.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: var(--beige);
    background-color: var(--black);
    padding: 22px 0 12px 0;
    position: relative;
}
.footer_overline {
    margin: 0 auto;
    margin-top: 5px;
    border-top: 1px solid var(--beige);
    width: 76%;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%);
    opacity: .9;
  }
  .footer_link {
    color: inherit;
    text-decoration: none;
  }
  .footer_link:hover {
    color: var(--white);
  }