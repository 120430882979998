.intro_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: var(--black);
    align-items: center;
    padding: 20px;
    padding-top: 30px;
    background-color: var(--white);
  }
.intro_heading {
    width: 100%;
    border-left: 5px solid var(--black);
    padding: 10px 10px 10px 10px;
    text-transform: uppercase;
    color: var(--black);
    font-size: 2.6rem;
    font-weight: 500;
    font-family: var(--font);
  }
  .lists_wrapper {
    padding: 20px;
    color: var(--black);
  }
  .list_header {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 10px 0 10px 0;
  }
  .policy_wrapper {
    padding: 20px;
    font-size: 0.8rem;
  }

  @media (min-width:900px) {
    .section_container {
      
      margin: 0 auto;
    }
    .intro_container {
      max-width: 70%;
      margin: 0 auto;
    }
    .intro_heading {
      font-size: 5rem;
    }
    .policy_wrapper {
      max-width: 70%;
      margin: 0 auto;
    }
    .policy_header {
      font-size: 1.3rem;
    }
    .lists_wrapper {
      font-size: 1rem;
    }
    .list_header {
      font-size: 1.5rem;
    }
  }