.about_section {
  background-color: var(--beige);
}
.about_wrapper {
  display: flex;
  justify-content: space-around;
  align-content: flex-start;
  flex-wrap: wrap;
  background-color: var(--beige);
}
.intro_container {
  width: 100%;
  display: flex;
  color: var(--black);
  align-items: center;
  padding: 20px;
  margin-top: 0px;
  margin-bottom: 30px;
  background-color: var(--white);
  
}
.intro_heading {
  width: 100%;
  border-left: 5px solid transparent;
  border-image: linear-gradient(to top, #f09433, #bc1888) 1;
  padding: 10px 20px 10px 20px;
  text-transform: uppercase;
  font-size: 2.8rem;
  font-weight: 500;
  font-family: var(--font);
}
.image_container {
  margin: 45px 20px 75px 20px;
  max-width: 350px;
  background-color: var(--white);
  padding: 5px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.75);
}
.profile_image {
  display: block;
  width: 100%;
  object-fit: contain;
  border-radius: 50%;
}
.description_container {
  font-family: var(--secondfont);
  max-width: 600px;
  padding: 40px;
  margin-inline: 20px;
  border-radius: 86px 20px 96px 20px;
  background-color: white;
  color: var(--black);
}

.bio_descritpion:nth-of-type(1) {
  font-size: 1.5rem;
  font-weight: 600;
}
.bio_descritpion {
  margin: 10px;
  line-height: 25px;
  font-size: 1rem;
  word-break: break-word;
}
.bio_link {
  text-decoration: none;
  text-transform: uppercase;
  color: var(--beige);
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--font);
  transition: .2s color linear;
}
.bio_link:hover {
color: var(--black);
}

@media (min-width: 900px) {
  .about_wrapper {
    justify-content: center;
    gap: 30px;
  }
  .image_container {
    order: 1;
    height: 350px;
  }
  .intro_container {
    padding-left: 50px;
  }
  .intro_heading {
    width: fit-content;
    text-align: center;
    font-size: 5rem;
  }
  .description_container {
    max-width: 800px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bio_descritpion {
    align-self: flex-start;
    font-size: 1.2rem;
  }
  .bio_link {
    font-size: 1.4rem;
  }
}
