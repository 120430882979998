.intro_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--white);
  align-items: center;
  padding: 20px;
  padding-top: 30px;
  background-color: var(--beige);
}
.intro_heading {
  width: 100%;
  border-left: 5px solid var(--white);
  padding: 10px 10px 10px 10px;
  text-transform: uppercase;
  color: var(--black);
  font-size: 2.6rem;
  font-weight: 500;
  font-family: var(--font);
}
.data_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.logo {
  padding: 80px 30px 0px 30px;
  max-width: 100%;
  object-fit: contain;
}
.brand_text {
  color: var(--black);
  text-transform: uppercase;
  margin-top: 20px;
  text-align: center;
  line-height: 5.2rem;
  word-break: break-word;
  font-family: var(--font);
  font-size: 2.3rem;
}
.description_container {
  color: var(--black);
  display: grid;
  padding: 0px 20px 00px 20px;
  align-items: center;
  font-family: var(--secondfont);
  text-align: center;
  max-width: 900px;
  gap: 0px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  line-height: 20px;
}
.description_span {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--font);
  cursor: pointer;
  transition: color 0.2s ease-in;
}
.description_span a {
  text-decoration: none;
  color: var(--black);
}
.description_span a:hover {
  color: var(--beige);
}
.description_span__first {
  display: inline-block;
  font-weight: 600;
  word-break: break-word;
}
.screens_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.screen_block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  margin-top: 20px;
  color: var(--black);
}
.screen_title {
  margin: 10px;
  font-family: var(--font);
  /* font-size: 1.8rem; */
}
.screen_image {
  width: 60%;
}

@media (min-width: 900px) {
  .intro_heading {
    font-size: 5rem;
    margin-left: 50px;
  }
  .logo {
    max-width: 40%;
  }
  .brand_text {
    margin-top: 30px;
    font-size: 4rem;
  }
  .description_container {
    margin-top: 50px;
    max-width: 1000px;
  }
  .description {
    line-height: 25px;
    font-size: 1.3rem;
  }
  .description_span {
    font-size: 1.4rem;
  }
  .screen_title {
    font-size: 1.4rem;
  }
}
