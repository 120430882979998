.contact_section {
    margin-top: 80px;
    padding: 10px;
    background-color: var(--black);
    color: var(--beige);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 30px 0 30px;
}
.contact_header {
    font-size: 2.8rem;
    text-transform: uppercase;
    font-family: var(--font);
    text-align: center;
    letter-spacing: 5px;
    margin: auto;
    margin-top: 80px;
}
.contact_text {
    margin-top: 30px;
    font-size: 1.3rem;
}
.social_icons {
    margin-top: 30px;
}
.icon_link {
    text-decoration: none;
    color: inherit;
    padding: 3px;
    margin: 0 13px 0 13px;
}
.icon_link:hover {
    color: var(--white);
}
.contact_logo__link {
    margin-top: 30px;
    width: 50%;
    
}
.contact_logo {
    width: 100%;
}
.shortcuts {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.shortcuts_links {
    text-decoration: none;
    margin: 10px;
    color: var(--beige);
    transition: .3s all ease;
}
.shortcuts_links:hover {
    color: var(--white);
}

@media (min-width:900px) {
    .contact_section {
        flex-direction: row;
    }
    .wrapper {
        order: 1;
    }
    .shortcuts {
        margin-left: 52px;
        align-items: start;
        width: auto;
        order: 2;
    }
    .shortcuts_links {
        margin: 5px 5px 5px 0;
    }
    .contact_header {
        margin-top: 20px;
    }
    .contact_logo__link {
        object-fit: contain;
        max-width: 300px;
    }
    .contact_logo {
        width: 100%;
    } 
}